/* ------------------------------------------ Hero ------------------------------------------ */
.hero {
    position: relative;
    padding-top: 150px;
}

.herocol-2 {
    width: 48%;
}

.hero-info h1 {
    font-family: var(--base-font);
    font-size: var(--h1-size);
    margin: 10px 0; 
}

.cta {
    display: flex;
    align-items: center;
    gap: 20px;
}

.hero-socials-list {
    align-items: center;
    display: flex;
    gap: 20px;
    list-style: none;
    padding-bottom: 20px;
    padding-top: 15px;
}

.social-list-item1 {
    padding: 0 10px;
}

.social-list-item1 img {
    width: 60%;
    padding: 5px 5px;
    background-color: var(--bg-color);
    border-radius: 7px;
}

.social-list-item2 {
    background-color: var(--bg-color);
    border-radius: 10px;
    padding: 5px 15px;
    color: white;
}

.social-list-item2:hover {
    background-color: var(--base-color);
}


.hero-button:hover {
    background: var(--base-color);
}

.hero-image {
    position: relative;
}

.hero-image img {
    width: 80%;
    border-radius: 50%;
}

@media (max-width: 50em) {    
    .hero-image img {
        width: 80%;
    }

    .hero-info h1 {
        font-size: 50px;
    }
}

@media (max-width: 33em) { 
    .herocol-2 {
        width: 95%
    }

    .hero-image img {
        width: 80%;
        margin: 0 10%;
    }

    .hero-info h1 {
        font-size: 30px;
    }

    .hero-info h3 {
        font-size: 20px;
    }

    .hero-info p {
        font-size: 14px;
    }

    .hero-socials-list {
        gap: 10px;
    }


    .social-list-item1 img {
        width: 48%;
    }

    .social-list-item2 {
        font-size: 11px;
    }
}