/* ------------------------------------------ About Me ------------------------------------------ */
.about-info {
    border-radius: 1rem;
    box-shadow: 0 0 2.8rem rgba(0,0,0,.15);
    font-size: 2rem;
    line-height: 1.5;
    padding: 2rem;   
    background-color: white;
}

.aboutme-important {
    color: var(--h1-color);
    font-weight: 500;
}

@media (max-width: 33em) {
    .about-info {
        padding: 1rem;
        width: 95%;
    }

    .about-info p {
        font-size: 1rem;
    }
}