/* ------------------------------------------ Footer ------------------------------------------ */
.col-3-footer {
    width: 32%;
}

.footer-area {
    background-color: var(--bg-color);
    color: white;
    display: grid;
    grid-template-columns: 50fr 25fr 25fr;
    padding: 2% 0 2% 18%;
}

.footer-area h3 {
    color: #ffffff;
}

.footer-widget {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2.4rem;
}

.footer-text p {
    color: #ffffff;
    font-size: 16px;
}

.footer-links {
    row-gap: 2.4rem;
}

.footer-links ul a {
    color: #ffffff;
    font-size: 16px;
    line-height: 30px;
}

.footer-socials img {
    width: 8%;
}

.footer-socials li{
    row-gap: 2.4rem;
    color: white;
    text-decoration: none;
}

.footer-social-color {
    padding: 4px 4px;
    background-color: white;
    border-radius: 5px;
    width: 90%;
}

.border-bottom {
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 5px;
}

.footer-copyright p {
    color: #ffffff;
    font-size: 12px;
}

@media (max-width: 39em) {
    .footer-area {
        background-color: white;
    }

    .footer-area .row {
        border-top: 2px solid #d2d2d2;
    }

    .footer-area h3, .footer-text p, .footer-links ul a, .footer-copyright p {
        color: black;
    }
}

@media (max-width: 33em) {
    .col-3-footer {
        width: 100%;
    }

    .footer-area {
        padding-left: 8px;
    }

    .footer-text p {
        text-align: justify;
        padding-left: 10px;
    }

    .footer-links li {
        text-align: center;
    }

    .footer-socials {
        flex-direction: row;
    }

    .footer-socials li {
        text-align: center;
    }

    .footer-socials img {
        width: 10%;
    }
}