/* ------------------------------------------ Skills Section ------------------------------------------ */
.skill-container {
    column-gap: 2.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
}

.skills-card {
    align-items: center;
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 0 2.8rem rgba(0, 0, 0, .15);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2.4rem 2.4rem;
}

.skill-items {
    column-gap: 3rem;
    row-gap: 2.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    width: 80%;
}

/* Figure */
.single-logo {
    position: relative;
}

/* Image */
.single-logo-logo {
    transition: all 0.3s;
    width: 120%;
}

/* Figure Caption */
.single-logo-description {
    position: absolute;
    font-size: 1rem;
    font-weight: 700;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity .3s;
}

.single-logo:hover .single-logo-logo{
    filter: blur(1rem);
}

.single-logo:hover .single-logo-description{
    opacity: 1;
}

@media (max-width: 69em) {
    .skill-container {
        margin: 0 auto;
        grid-template-columns: 1fr;
        width: 80%;
        justify-items: center;
        row-gap: 4rem;
    }

    .skills-card {
        padding: 1rem 4rem;
    }

    .skill-items {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        row-gap: 2rem;
        column-gap: 6rem;
    }
}

@media (max-width: 33em) {
    .skill-container {
        margin-left: 10px;
    }
    .single-logo-logo {
        width: 200%;
    }
}