/* ------------------------------------------ Project section ------------------------------------------ */
.project-info li {
    line-height: 30px;
}

.project-image img {
    width: 110%;
    padding: 30px 15px;
    align-content: space-between;
    border-radius: 20px;
}

.project-tech-img {
    margin: 0 3px;
    width: 7%;
}

.project-button {
    border: 3px solid var(--base-color);
    border-radius: 10px;
    padding: 5px 15px;
    color: black;
    position: relative;
    margin-left: 30px;
    top: -15px;
}

.project-button:hover {
    background-color: var(--base-color);
    color: white;
}

.project-slide {
    display: none;
}

.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {opacity: .4;}
    to {opacity: 1;}
}

@media (max-width: 55em) {
    .project-image img {
        width: 80%;
        padding: 0 15px;
        justify-items: center;
        margin-top: -90px;
        margin-bottom: 40px;
    }

    .project-tech-img {
        width: 5%;
    }
}

@media (max-width: 33em) {
    .projects {
        margin-left: 0px;
        padding-left: 18px;
    }

    .project-tech-img {
        width: 11%;
    }
}